import React from 'react'

const test = () => {
    return (
        <div>
            <p>This is a test page.</p>
        </div>
    )
}

export default test
